import React from 'react'
import styled from 'styled-components';
import Request from './../Request';
import { useStaticQuery, graphql } from "gatsby"
import { ImLocation } from 'react-icons/im'
import MapWrapper from './MapWrapper';

const Body = () => {
    const data = useStaticQuery(graphql`
    query BodyContactQuery {
        allMiscInfoJson {
          edges {
            node {
              address
            }
          }
        }
      }
      
    `)


    return (
        <Container>
            <HeroContact>Contact Us</HeroContact>
            <Wrapper>
                <Info>
                    <ImLocation css={`font-size: 2rem`} />
                    {data.allMiscInfoJson.edges[0].node.address.map((item, index) => (
                        <ContactInfo key={index}>{item}</ContactInfo>
                    ))}
                </Info>
                <ContactMap>
                    <MapWrapper />
                </ContactMap>
                <ContactUs><Request /></ContactUs>
            </Wrapper>
        </Container>
    )
}

export default Body

const Container = styled.div`
    margin-top: 70px;
`
const HeroContact = styled.h1`
    background: linear-gradient(to bottom, #1C333A 10%,#1B353C 50%,#1D3238 70%, #187E9C 100%);
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
    height: 50vh;
    padding: 0 1rem;
    font-size: clamp(1.5rem,6vw, 3rem);
`
const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
`
const ContactInfo = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
`
const Info = styled.div`
    line-height: 2;
    `
const ContactMap = styled.div`
    border: 2px solid #0F6D89 ;
    width: 40rem;
    height: 40rem;
    margin: 1rem;

`
const ContactUs = styled.div`
    max-width: 400px;
`
