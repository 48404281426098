import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import "leaflet/dist/images/marker-shadow.png";


const MapWrapper = () => {
    typeof window !== 'undefined' && delete L.Icon.Default.prototype._getIconUrl;

    typeof window !== 'undefined' && L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });
    const position = [-1.2191, 36.8439]
    const zoom = 15


    return (
        <MapContainer
            center={position}
            zoom={zoom}
            style={{ height: "100%", width: "100%" }}
            scrollWheelZoom={false}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}>
                <Popup>Tripleaim Software</Popup>
            </Marker>
        </MapContainer>
    )
}

export default MapWrapper
