import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Body from '../components/contact/Body'

const Contact = () => (
    <Layout>
        <SEO title="Contact Us"
            description="Health software company in Kenya, building Software Applications, Data Analytics solutions and digital marketing for healthcare"
            keywords="health technology Kenya, health system in Kenya, health information system in Kenya, digital health Kenya, software company in kenya,
                    digital marketing in kenya, marketing agency in kenya, web design kenya"
            siteUrl="https://www.tripleaimsoftware.com/contact/"
            canonical="https://www.tripleaimsoftware.com/contact/"
        >
        </SEO>
        <Body />
    </Layout>
)
export default Contact
